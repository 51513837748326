@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .amount-label {
        @apply p-3 bg-gray-200 text-center rounded w-full text-sm md:text-base relative;
    }

    input:checked + .amount-label {
        @apply bg-primary text-white focus:ring-primary focus:border-primary;
    }

    input:checked + .amount-label .arrow-up {
        display: block;
    }

    /* Calendar css */

    .simple-calendar {
        @apply w-full rounded lg:w-3/5;
    }

    .simple-calendar th {
        @apply border-b-0 text-secondary text-base font-semibold;
    }

    .calendar-table-container table {
        overflow-y: scroll;
        height: auto;
    }

    .calendar-table tbody {
        width: 100%;
        display: table;
    }

    .calendar-heading {
        @apply flex justify-between border-r p-4 items-center;
    }

    .calendar-heading a {
        @apply font-semibold;
    }

    .calendar-title {
        @apply text-xl text-primary font-semibold;
    }

    .day {
        @apply text-center;
    }

    /* end */

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 10px solid currentColor;
        @apply mx-auto;
        left: 0;
        right: 0;
        display: none;
    }

    input:checked + .payment-method-label {
        @apply text-primary;
    }

    .input-label {
        @apply text-gray-700 font-bold text-sm mb-1;
    }

    .input-field {
        @apply px-3 py-1 focus:ring-primary focus:border-primary border w-full shadow-sm border-gray-300 rounded-md disabled:opacity-75;
    }

    /* ------------------->> font */
    .font-manrope {
        font-family: "Manrope", sans-serif;
    }
    /* ------------------->> top */
    .top-7px {
        top: 7px;
    }
    /* ------------------->> width */
    .w-7px {
        width: 7px;
    }
    .w-14px {
        width: 14px;
    }
    .w-16px {
        width: 16px;
    }
    .w-18px {
        width: 18px;
    }
    .w-21px {
        width: 21px;
    }
    .w-30px {
        width: 30px;
    }
    .w-40px {
        width: 40px;
    }
    .w-46px {
        width: 46px;
    }
    .w-49px {
        width: 49px;
    }
    .w-52px {
        width: 52px;
    }
    .w-57px {
        width: 57px;
    }
    .w-60px {
        width: 60px;
    }
    .w-76px {
        width: 76px;
    }
    .w-79px {
        width: 79px;
    }
    .w-82px {
        width: 82px;
    }
    .w-86px {
        width: 86px;
    }
    .w-90px {
        width: 90px;
    }
    .w-93px {
        width: 93px;
    }
    .w-98px {
        width: 98px;
    }
    .w-99px {
        width: 99px;
    }
    .w-100px {
        width: 100px;
    }
    .w-101px {
        width: 101px;
    }
    .w-102px {
        width: 102px;
    }
    .w-108px {
        width: 108px;
    }
    .w-110px {
        width: 110px;
    }
    .w-114px {
        width: 114px;
    }
    .w-122px {
        width: 122px;
    }
    .w-124px {
        width: 124px;
    }
    .w-132px {
        width: 132px;
    }
    .w-138px {
        width: 138px;
    }
    .w-141px {
        width: 141px;
    }
    .w-145px {
        width: 145px;
    }
    .w-147px {
        width: 147px;
    }
    .w-149px {
        width: 149px;
    }
    .w-150px {
        width: 150px;
    }
    .w-156px {
        width: 156px;
    }
    .w-157px {
        width: 157px;
    }
    .w-160px {
        width: 160px;
    }
    .w-176px {
        width: 176px;
    }
    .w-181px {
        width: 181px;
    }
    .w-184px {
        width: 184px;
    }
    .w-188px {
        width: 188px;
    }
    .w-204px {
        width: 204px;
    }
    .w-233px {
        width: 233px;
    }
    .w-246px {
        width: 246px;
    }
    .w-265px {
        width: 265px;
    }
    .w-296px {
        width: 296px;
    }
    .w-302px {
        width: 302px;
    }
    .w-320px {
        width: 320px;
    }
    .w-343px {
        width: 343px;
    }
    .w-348px {
        width: 348px;
    }
    .w-373px {
        width: 373px;
    }
    .w-386px {
        width: 386px;
    }
    .w-400px {
        width: 400px;
    }
    .w-420px {
        width: 420px;
    }
    .w-423px {
        width: 423px;
    }
    .w-425px {
        width: 425px;
    }
    .w-430px {
        width: 430px;
    }
    .w-431px {
        width: 431px;
    }
    .w-435px {
        width: 435px;
    }
    .w-436px {
        width: 436px;
    }
    .w-437px {
        width: 437px;
    }
    .w-480px {
        width: 480px;
    }
    .w-512px {
        width: 512px;
    }
    .w-570px {
        width: 570px;
    }
    .w-577px {
        width: 577px;
    }
    .w-597px {
        width: 597px;
    }
    .w-610px {
        width: 610px;
    }
    .w-664px {
        width: 664px;
    }
    .w-667px {
        width: 667px;
    }
    .w-700px {
        width: 700px;
    }
    .w-894px {
        width: 894px;
    }
    .w-921px {
        width: 921px;
    }
    .w-948px {
        width: 948px;
    }
    .w-978px {
        width: 978px;
    }
    .w-1014px {
        width: 1014px;
    }
    .w-1364px {
        width: 1364px;
    }
    .w-1367px {
        width: 1367px;
    }
    .w-1372px {
        width: 1372px;
    }
    .w-1374px {
        width: 1374px;
    }
    .w-1380px {
        width: 1380px;
    }
    .w-1381px {
        width: 1381px;
    }
    .w-1382px {
        width: 1382px;
    }
    .w-1384px {
        width: 1384px;
    }
    .w-1385px {
        width: 1385px;
    }
    .w-1386px {
        width: 1386px;
    }
    .w-1387px {
        width: 1387px;
    }
    .w-1388px {
        width: 1388px;
    }
    .w-1389px {
        width: 1389px;
    }
    .w-1390px {
        width: 1390px;
    }
    .w-1395px {
        width: 1395px;
    }
    .w-1398px {
        width: 1398px;
    }
    .w-1399px {
        width: 1399px;
    }
    .w-1400px {
        width: 1400px;
    }
    .w-1405px {
        width: 1405px;
    }
    .w-1408px {
        width: 1408px;
    }
    .w-1409px {
        width: 1409px;
    }
    .w-1410px {
        width: 1410px;
    }
    .w-1480px {
        width: 1480px;
    }
    .w-1566px {
        width: 1566px;
    }
    /* ------------------->> height */
    .h-10px {
        height: 10px;
    }
    .h-12px {
        height: 12px;
    }
    .h-14px {
        height: 14px;
    }
    .h-18px {
        height: 18px;
    }
    .h-20px {
        height: 20px;
    }
    .h-22px {
        height: 22px;
    }
    .h-24px {
        height: 24px;
    }
    .h-28px {
        height: 28px;
    }
    .h-40px {
        height: 40px;
    }
    .h-44px {
        height: 44px;
    }
    .h-45px {
        height: 45px;
    }
    .h-48px {
        height: 48px;
    }
    .h-49px {
        height: 49px;
    }
    .h-50px {
        height: 50px;
    }
    .h-56px {
        height: 56px;
    }
    .h-60px {
        height: 60px;
    }
    .h-61px {
        height: 61px;
    }
    .h-63px {
        height: 63px;
    }
    .h-64px {
        height: 64px;
    }
    .h-75px {
        height: 75px;
    }
    .h-79px {
        height: 79px;
    }
    .h-81px {
        height: 81px;
    }
    .h-82px {
        height: 82px;
    }
    .h-94px {
        height: 94px;
    }
    .h-102px {
        height: 102px;
    }
    .h-105px {
        height: 105px;
    }
    .h-106px {
        height: 106px;
    }
    .h-109px {
        height: 109px;
    }
    .h-110px {
        height: 110px;
    }
    .h-113px {
        height: 113px;
    }
    .h-124px {
        height: 124px;
    }
    .h-130px {
        height: 130px;
    }
    .h-132px {
        height: 132px;
    }
    .h-134px {
        height: 134px;
    }
    .h-156px {
        height: 156px;
    }
    .h-157px {
        height: 157px;
    }
    .h-167px {
        height: 167px;
    }
    .h-181px {
        height: 181px;
    }
    .h-203px {
        height: 203px;
    }
    .h-209px {
        height: 209px;
    }
    .h-226px {
        height: 226px;
    }
    .h-241px {
        height: 241px;
    }
    .h-258px {
        height: 258px;
    }
    .h-290px {
        height: 290px;
    }
    .h-311px {
        height: 311px;
    }
    .h-340px {
        height: 340px;
    }
    .h-345px {
        height: 345px;
    }
    .h-348px {
        height: 348px;
    }
    .h-350px {
        height: 350px;
    }
    .h-355px {
        height: 355px;
    }
    .h-356px {
        height: 356px;
    }
    .h-360px {
        height: 360px;
    }
    .h-365px {
        height: 365px;
    }
    .h-368px {
        height: 368px;
    }
    .h-369px {
        height: 369px;
    }
    .h-370px {
        height: 370px;
    }
    .h-372px {
        height: 372px;
    }
    .h-375px {
        height: 375px;
    }
    .h-378px {
        height: 378px;
    }
    .h-373px {
        height: 373px;
    }
    .h-375px {
        height: 375px;
    }
    .h-378px {
        height: 378px;
    }
    .h-399px {
        height: 399px;
    }
    .h-459px {
        height: 459px;
    }
    .h-460px {
        height: 460px;
    }
    .h-480px {
        height: 480px;
    }
    .h-483px {
        height: 483px;
    }
    .h-608px {
        height: 608px;
    }
    .h-609px {
        height: 609px;
    }
    .h-610px {
        height: 610px;
    }
    .h-616px {
        height: 616px;
    }
    .h-650px {
        height: 650px;
    }
    .h-670px {
        height: 670px;
    }
    .h-676px {
        height: 676px;
    }
    .h-678px {
        height: 678px;
    }
    .h-701px {
        height: 701px;
    }
    .h-711px {
        height: 711px;
    }
    .h-724px {
        height: 724px;
    }
    .h-736px {
        height: 736px;
    }
    .h-743px {
        height: 743px;
    }
    .h-972px {
        height: 972px;
    }
    .h-979px {
        height: 979px;
    }
    /* ------------------->> font color */
    .text-gray-150 {
        color: #8b8b8b;
    }
    .text-gray-250 {
        color: #787878;
    }
    .text-gray-350 {
        color: #393939;
    }
    .text-black-50 {
        color: #2d2d2d;
    }
    .text-blue-650 {
        color: #0067ac;
    }
    .text-blue-750 {
        color: #0067a5;
    }
    .text-green-450 {
        color: #13b934;
    }
    .text-orange-550 {
        color: #f26722;
    }
    .text-orange-650 {
        color: #ff5e16;
    }
    .text-orange-750 {
        color: #f0781e;
    }
    .text-orange-850 {
        color: #ac4b02;
    }
    .text-brown-550 {
        color: #b88b04;
    }
    /* ------------------->> background color */
    .bg-white-15 {
        background-color: #fff7f3;
    }
    .bg-gray-45 {
        background-color: #f6f8f8;
    }
    .bg-gray-55 {
        background-color: #f9f9f9;
    }
    .bg-gray-150 {
        background-color: #f8fcff;
    }
    .bg-gray-250 {
        background-color: #f5f5f5;
    }
    .bg-gray-350 {
        background-color: #e1e1e1;
    }
    .bg-blue-5 {
        background-color: #fbfdff;
    }
    .bg-blue-10 {
        background-color: #dce6ec;
    }
    .bg-blue-15 {
        background-color: #f9fdff;
    }
    .bg-blue-25 {
        background-color: #e1f1fc;
    }
    .bg-blue-550 {
        background-color: #bacfdc;
    }
    .bg-blue-650 {
        background-color: #0067ac;
    }
    .bg-blue-750 {
        background-color: #0067a5;
    }
    .bg-yellow-10 {
        background-color: #fffaeb;
    }
    .bg-yellow-15 {
        background-color: #fffbee;
    }
    .bg-yellow-25 {
        background-color: #fef3d5;
    }
    .bg-yellow-30 {
        background-color: #ffcc00;
    }
    .bg-orange-10 {
        background-color: #fff5f1;
    }
    .bg-orange-550 {
        background-color: #f26722;
    }
    .bg-orange-650 {
        background-color: #f0781e;
    }
    .bg-orange-750 {
        background-color: #ff5e16;
    }
    .bg-green-550 {
        background-color: #05a724;
    }
    .bg-red-10 {
        background-color: #fde6db;
    }
    /* ------------------->> border styling */
    .border-color-none {
        border-color: none;
    }
    .border-white-1 {
        border: 1px solid #ffffff;
    }
    .border-white-0 {
        border: 0.5px solid #ffffff;
    }
    .border-gray-15 {
        border: 1px solid #dce6ec;
    }
    .border-gray-50 {
        border: 2px solid #e7eff1;
    }
    .border-gray-55 {
        border: 2px solid #d9d9d9;
    }
    .border-gray-150 {
        border: 1px solid #707070;
    }
    .border-blue-50 {
        border: 1px solid #dce6ec;
    }
    .border-blue-550 {
        border: 2px solid #bacfdc;
    }
    .border-blue-650 {
        border: 1px solid #0067ac;
    }
    .border-orange-550 {
        border: 1px solid #f26722;
    }
    .border-orange-750 {
        border: 1px solid #f0781e;
    }
    .border-orange-650 {
        border: 2px solid #f0781e;
    }
    .border-yellow-350 {
        border: 2px solid #f2d06b;
    }
    .border-yellow-450 {
        border: 2px solid #f8b903;
    }
    .border-brown-550 {
        border: 1px solid #f2d06b;
    }
    .border-orange-850 {
        border: 1px solid #f2d06b;
    }
    .border-l-orange-650 {
        border-left: 5px;
        border-style: solid;
        border-left-color: #f0781e;
    }
    .border-b-orange-650 {
        border-bottom: 1px;
        border-style: solid;
        border-bottom-color: #f2d06b;
    }
}

.home-container {
    @apply container px-6;
}

.day.is-active {
    @apply bg-orange-550 text-white;
}
